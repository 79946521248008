import styled from 'styled-components'

const Footer = () => (
  <Wrapper>
    <Link href='https://store.goela.pt'>e-Store</Link>
    <Link href='https://www.facebook.com/Goela-110085693870941'>Facebook</Link>
    <Link href='https://www.instagram.com/goela.porto/'>Instagram</Link>
  </Wrapper>
)

const Wrapper = styled.div`
  width: 100%;
  padding: 30px;
  background-color: #739879;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Link = styled.a`
  text-decoration: none;
  font-size: 1.5em;
  color: #4c4c4c;
  padding: 0px 10px;
  @media (max-width: 768px) {
    font-size: 1em;
  }
`

export default Footer
