import { useEffect, useState } from 'react'
import styled from 'styled-components'

export default function EditableCounter({ value, onChange }) {
  const [currentValue, setCurrentValue] = useState(value)

  useEffect(() => {
    if (currentValue !== value) onChange(currentValue)
  }, [currentValue])

  const decrementValue = () => {
    if (currentValue > 0) setCurrentValue(currentValue - 1)
  }

  const incrementValue = () => {
    setCurrentValue(currentValue + 1)
  }

  const updateValue = (e) => {
    var i = e.target.value
    if (!isNaN(i) && i >= 0) setCurrentValue(Number(i))
  }

  return (
    <Wrapper>
      <Button onClick={decrementValue}>-</Button>
      <Input
        type='text'
        inputmode='numeric'
        value={currentValue}
        onChange={updateValue}
      />
      <Button onClick={incrementValue}>+</Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`
const Button = styled.button`
  border: none;
  width: 100px;
  font-family: Parcel, Arial, sans-serif;
  font-size: 6em;
  background-color: #739879;
  text-decoration: none;
  border-radius: 6px;
  @media (max-width: 768px) {
    font-size: 3em;
    width: 50px;
  }
`

const Input = styled.input`
  border: none;
  width: 200px;
  font-family: Parcel, Arial, sans-serif;
  font-size: 6em;
  text-align: center;
  background-color: #c4d2c3;
  text-decoration: none;
  @media (max-width: 768px) {
    font-size: 3em;
    width: 100px;
  }
`
