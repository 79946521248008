const { createContext, useState, useContext } = require('react')

const authContext = createContext()

function useAuth() {
  const [authed, setAuthed] = useState(false)

  return {
    authed,
    login(data) {
      return new Promise((res) => {
        setAuthed(data === process.env.REACT_APP_EDIT_PIN)
        res()
      })
    },
    logout() {
      return new Promise((res) => {
        setAuthed(false)
        res()
      })
    },
  }
}

export function AuthProvider({ children }) {
  const auth = useAuth()

  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export default function AuthConsumer() {
  return useContext(authContext)
}
