import { useEffect, useState } from 'react'
import EditableCounter from '../components/EditableCounter'
import db from '../data/firebase.config'
import { doc, onSnapshot, setDoc } from 'firebase/firestore'
import { useNavigate, useParams } from 'react-router'
import styled from 'styled-components'

export default function GiftPage({ isEditable }) {
  const params = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState({ count: 0 })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    readValue()
  }, [params])

  const readValue = () => {
    onSnapshot(doc(db, 'gifts', params.giftId), (doc) => {
      if (doc.exists()) {
        setData(doc.data())
        setLoading(false)
      } else navigate('/')
    })
  }

  const storeValue = async (value) => {
    await setDoc(doc(db, 'gifts', params.giftId), { ...data, count: value })
  }

  if (loading) return null
  return (
    <Wrapper>
      {isEditable ? (
        <OwnerView count={data.count} onChangeValue={storeValue} />
      ) : (
        <ClientView count={data.count} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0px 50px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`

const Text = styled.p`
  font-size: 2em;
`

const Counter = styled.div`
  font-size: 8em;
`

const ClientView = ({ count }) => (
  <>
    <Text>
      Dear Ludo,
      <br />
      <br />
      You have
    </Text>
    <Counter>{count}</Counter>
    <Text>cookies to redeem.</Text>
  </>
)

const OwnerView = ({ count, onChangeValue }) => (
  <>
    <Text>How many cookies for Ludo?</Text>
    <br />
    <br />
    <EditableCounter value={count} onChange={(value) => onChangeValue(value)} />
  </>
)
