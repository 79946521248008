import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import './index.css'
import App from './App'
import GiftPage from './pages/GiftPage'
import Home from './pages/Home'
import RequireAuth from './components/RequireAuth'
import Login from './components/Login'
import { AuthProvider } from './hooks/useAuth'
import NotFound from './pages/NotFound'

render(
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path='/' element={<App />}>
          <Route path='' element={<Home />} />
          <Route path='/gift/'>
            <Route path='' element={<Navigate to='/' replace />} />
            <Route path=':giftId/' element={<GiftPage />} />
            <Route
              path=':giftId/edit'
              element={
                <RequireAuth>
                  <GiftPage isEditable={true} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path='login' element={<Login />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
