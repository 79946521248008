import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import Footer from './components/Footer'

const App = () => (
  <Container>
    <Wrapper>
      <Content>
        <Outlet />
      </Content>
    </Wrapper>
    <Footer />
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
`

const Wrapper = styled.div`
  max-width: 1600px;
  width: 100%;
  margin: auto;
  flex-grow: 1;
`

const Content = styled.div`
  height: 100%;
  display: flex;
`

export default App
