import styled from 'styled-components'

const NotFound = () => (
  <Wrapper>
    <Title>Uh Oh!</Title>
    <Content>No, this isn't the place you want to visit.</Content>
    <Link href='/'>Shall we go back?</Link>
  </Wrapper>
)

const Wrapper = styled.div`
  padding: 50px;
`

const Title = styled.h1`
  font-size: 6em;
`
const Content = styled.p`
  font-size: 2em;
`

const Link = styled.a`
  text-decoration: none;
  font-size: 2em;
  color: #d16014;
`

export default NotFound
