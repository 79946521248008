import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'
import useAuth from '../hooks/useAuth'

export default function Login() {
  const navigate = useNavigate()
  const { login } = useAuth()
  const { state } = useLocation()
  const [value, setValue] = useState('')

  const handleLogin = () => {
    login(value)
      .then(() => {
        navigate(state.path || '/')
      })
      .catch((err) => alert(err))
  }

  return (
    <Wrapper>
      <Input
        value={value}
        type='password'
        onChange={(e) => setValue(e.target.value)}
      />
      <Button onClick={handleLogin}>Login</Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 50px;
`

const Input = styled.input`
  border: none;
  border-radius: 6px;
  width: 200px;
  font-family: Parcel, Arial, sans-serif;
  font-size: 2em;
  text-align: center;
  background-color: #ffffff;
  text-decoration: none;
`

const Button = styled.button`
  border: none;
  font-family: Parcel, Arial, sans-serif;
  font-size: 2em;
  margin-left: 10px;
  background-color: #739879;
  text-decoration: none;
  border-radius: 6px;
  padding: 0px 10px;
`
