import styled from 'styled-components'
import logo from '../logo.jpeg'

export default function Home() {
  return (
    <Wrapper>
      <MainImage src={logo} alt='Goela' />
      <Title>Menus</Title>
      <MenusWrapper>
        <MenuImage src='/media/goela-menu.jpeg' alt='Menu' />
        <MenuImage src='/media/goela-carta.jpeg' alt='Carta' />
      </MenusWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  @media (max-width: 1200px) {
    padding: 40px;
  }
  @media (max-width: 768px) {
    padding: 30px;
  }
`

const MainImage = styled.img`
  max-width: 100%;
  max-height: 400px;
  object-fit: contain;
`

const Title = styled.h2`
  font-size: 2em;
`

const MenusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const MenuImage = styled.img`
  width: 30%;
  object-fit: cover;
  @media (max-width: 1200px) {
    width: 40%;
  }
  @media (max-width: 768px) {
    width: 90%;
    margin: 20px 0px;
  }
`
